import React from "react";

class FeedbackPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      message: "",
      emailError: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    switch (event.target.name) {
      case "name":
        this.setState({ name: event.target.value });
        break;
      case "message":
        this.setState({ message: event.target.value });
        break;
    }
  }

  handleSubmit() {
    event.preventDefault();
  }

  render() {
    return (
      <main>
        <div className="top-info">
          <h2 className="text-center">Feedback</h2>
        </div>
        <div className="container">
          <div className="row paddingTop-25">
          The team behind Namia aims to constantly improve and extend the functionality of the services provides, therefore any feedback would be much appreciated.
          </div>
          <div className="row paddingTop-25">
            <div className="col-12 ">
              <form onSubmit={this.handleSubmit}>
                <div className="offset-sm-3 col-sm-6 align-self-center">
                  <label>
                    Name <span className="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={this.state.name}
                    name="name"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="offset-sm-3 col-sm-6 paddingTop-15">
                  <label>
                    Message <span className="asterisk">*</span>
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    required
                    cols="30"
                    rows="5"
                    value={this.state.message}
                    name="message"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="paddingTop-25 paddingLeft-15 offset-sm-3">
                  <button
                    type="submit"
                    className="btn btn-send padding-side-15"
                  >
                    <strong>Send</strong>
                  </button> 
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default FeedbackPage;
