import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "./home/HomePage";
import AboutPage from "./about/AboutPage";
import Header from "./common/header/Header";
import PageNotFound from "./PageNotFound";
import Footer from "./common/footer/Footer";
import DonwloadPage from "./download/DownloadPage";
import GetStartedPage from "./getStarted/GetStartedPage";
import ContactPage from "./contact/ContactPage";
import PrivacyPage from "./privacy/PrivacyPage";
import FeedbackPage from "./feedback/FeedbackPage";
import CookiePage from './cookie/CookiePage';

function App() {
  return (
    <div>
      <Header />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/index.html" component={HomePage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/download" component={DonwloadPage} />
        <Route path="/getstarted" component={GetStartedPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/privacy" component={PrivacyPage} />
        <Route path="/cookie" component={CookiePage} />
        <Route path="/feedback" component={FeedbackPage} />
        <Route component={PageNotFound} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
