import React from "react";

const DonwloadPage = () => {
  return (
    <main>
      <div className="top-info">
        <h2 className="text-center">Download</h2>
      </div>
      <div></div>
    </main>
  );
};

export default DonwloadPage;
