import React from "react";
import BestSellers from "./BestSellers";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <>
      <main>
        <div className="mainContent">
          <div className="backGround">
            <img
              src="../../public/img/TextLarge.png"
              className="img-fluid padding10 mx-auto d-block"
              alt="NaMia logo"
            />
            <div className="slogan">
              Never pay extra while shopping at Amazon
            </div>
            <div className="sloganSmall">
              Instant price comparison and price alert
            </div>
            <div className="container-fluid">
              <div className="content">
                <div className="row backGroundButtons justify-content-center">
                  <div className="col-md-4 text-center">
                    <Link
                      to="/getstarted"
                      className="px-5 btn btn-outline-light btn-lg header-button-width-200"
                    >
                      Get started
                    </Link>
                  </div>
                  <div className="col-md-4 text-center padding-top-buttons-sm-15">
                    <Link
                      to="/download"
                      className="px-5 btn btn-outline-namia btn-lg header-button-width-200"
                    >
                      Download
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row paddingTop-25">
            <div className="col-sm-4">
              <h3 className="grey">Price comparison</h3>
              Compare prices and delivery costs for a chosen product, across Amazon sites in Europe, which will deliver to your country.
            </div>
            <div className="col-sm-4 padding-top-buttons-sm-15">
              <h3 className="grey">Price alert</h3>
              Receive a discreet notification, if a cheaper price is found on Amazon sites in Europe, for the product you are looking at.
            </div>
            <div className="col-sm-4 padding-top-buttons-sm-15">
              <h3 className="grey">Browser extension</h3>
              Namia is free browser extension for Amazon products price comparison between UK and Europe Amazon web sites. It&lsquo;s compatible with the most popular browsers - Chrome, Firefox, Edge and Opera.<br />
              Namia automatically compare and display prices, saving consumer&lsquo;s time  from manually checking different Amazon country sites
            </div>
          </div>
        </div>

        <BestSellers />
      </main>
    </>
  );
};

export default HomePage;
