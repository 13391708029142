import React from "react";
import { useLocation, Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

const Header = () => {
  let location = useLocation();

  if (location.pathname === "/") {
    return <div></div>;
  } else {
    return (
      <div className="header">
        <div className="container-fluid">
          <div className="content">
            <Navbar expand="lg" variant="dark">
              <Navbar.Brand as={Link} to="/">
                <img
                  src="../../public/img/logo-40.png"
                  className="img-fluid padding10"
                  alt="NaMia logo"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link as={Link} to="/getstarted">Get Started</Nav.Link>
                  <Nav.Link as={Link} to="/download">Download</Nav.Link>
                  <Nav.Link as={Link} to="/contact">Contact Us</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>

        {/* <div className="container-fluid">
            <div className="content">
              <div className="d-none d-md-block">
                <div className="row">
                <div className="col col-2">
                  {location.pathname !== "/" ? <Logo /> : null}
                </div>
              </div>
            </div>
            <div className="d-md-none">
              <div className="row">
                <div className="col-12">
                  {location.pathname !== "/" ? <LogoMobile /> : null}
                </div>
              </div>
            </div>
          </div> 
        </div>*/}
      </div>
    );
  }

  //   <>

  //     {/* <div className="d-none d-md-block">
  //       <div className="row">
  //         <div className="col col-2">
  //           {location.pathname !== "/" ? <Logo /> : null}
  //         </div>
  //         <div className="col-9 col-sm-8 col-md-9 padding20 padding-right">
  //           {location.pathname !== "/" ? <SearchBox /> : null}
  //         </div>
  //         <div className="col col-md-1">
  //           <LoginButtons />
  //         </div>
  //       </div>
  //     </div>
  //     <div className="d-md-none">
  //       <div className="row">
  //         <div className="col-12">
  //           {location.pathname !== "/" ? <LogoMobile /> : null}
  //           {location.pathname === "/" ? <LoginButtons /> : null}
  //         </div>
  //       </div>
  //       <div className="row">
  //         <div className="col-12">
  //           {location.pathname !== "/" ? <SearchBox /> : null}
  //         </div>
  //       </div>
  //     </div> */}
  //   </>
  // );
};

export default Header;
