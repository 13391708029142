import React, { Component } from "react";
import BestSellerForm from "./BestSellerForm";

class BestSellers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    fetch("https://api.namia.co.uk/popularProducts/bestSellers/uk")
      .then((c) => c.json())
      .then((data) => this.setState({ data }));
  }

  handleClick = (request) => {
    loadData(request).then((data) => this.setState({ data }));
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="content">
          <div className="bestSellerHeader paddingTop-25">
            <nav>
              <div
                className="nav nav-tabs justify-content-center"
                id="nav-tab"
                role="tablist"
              >
                <a
                  className="nav-item nav-link active"
                  id="nav-bestSellers-tab"
                  data-toggle="tab"
                  href="#nav-bestSellers"
                  role="tab"
                  aria-controls="nav-bestSellers"
                  aria-selected="true"
                  onClick={() => this.handleClick("bestSellers")}
                >
                  Best Sellers
                </a>
                <a
                  className="nav-item nav-link"
                  id="nav-hotNewReleases-tab"
                  data-toggle="tab"
                  href="#nav-hotNewReleases"
                  role="tab"
                  aria-controls="nav-hotNewReleases"
                  aria-selected="false"
                  onClick={() => this.handleClick("hotNewReleases")}
                >
                  Hot New Releases
                </a>
                <a
                  className="nav-item nav-link"
                  id="nav-moversAndShakers-tab"
                  data-toggle="tab"
                  href="#nav-moversAndShakers"
                  role="tab"
                  aria-controls="nav-moversAndShakers"
                  aria-selected="false"
                  onClick={() => this.handleClick("moversAndShakers")}
                >
                  Movers and Shakers
                </a>
                <a
                  className="nav-item nav-link"
                  id="nav-mostWishedFor-tab"
                  data-toggle="tab"
                  href="#nav-mostWishedFor"
                  role="tab"
                  aria-controls="nav-mostWishedFor"
                  aria-selected="false"
                  onClick={() => this.handleClick("mostWishedFor")}
                >
                  Most Wished For
                </a>
                <a
                  className="nav-item nav-link"
                  id="nav-mostGifted-tab"
                  data-toggle="tab"
                  href="#nav-mostGifted"
                  role="tab"
                  aria-controls="nav-mostGifted"
                  aria-selected="false"
                  onClick={() => this.handleClick("mostGifted")}
                >
                  Most Gifted
                </a>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-bestSellers"
                role="tabpanel"
                aria-labelledby="nav-bestSellers-tab"
              >
                <BestSellerForm data={this.state} />
              </div>
              <div
                className="tab-pane fade"
                id="nav-hotNewReleases"
                role="tabpanel"
                aria-labelledby="nav-hotNewReleases-tab"
              >
                <BestSellerForm data={this.state} />
              </div>
              <div
                className="tab-pane fade"
                id="nav-moversAndShakers"
                role="tabpanel"
                aria-labelledby="nav-moversAndShakers-tab"
              >
                <BestSellerForm data={this.state} />
              </div>
              <div
                className="tab-pane fade"
                id="nav-mostWishedFor"
                role="tabpanel"
                aria-labelledby="nav-mostWishedFor-tab"
              >
                <BestSellerForm data={this.state} />
              </div>
              <div
                className="tab-pane fade"
                id="nav-mostGifted"
                role="tabpanel"
                aria-labelledby="nav-mostGifted-tab"
              >
                <BestSellerForm data={this.state} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function loadData(category) {
  switch (category) {
    case "bestSellers":
      return fetch(
        "https://api.namia.co.uk/popularProducts/bestSellers/uk"
      ).then((res) => res.json());
    case "hotNewReleases":
      return fetch(
        "https://api.namia.co.uk/popularProducts/hotNewReleases/uk"
      ).then((res) => res.json());
    case "moversAndShakers":
      return fetch("https://api.namia.co.uk/popularProducts/moversAndShakers/uk")
        .then((res) => res.json());
    case "mostWishedFor":
      return fetch("https://api.namia.co.uk/popularProducts/mostWishedFor/uk")
        .then((res) => res.json());
    case "mostGifted":
      return fetch("https://api.namia.co.uk/popularProducts/mostGifted/uk")
        .then((res) => res.json());
    default:
      return fetch("https://api.namia.co.uk/popularProducts/bestSellers/uk")
        .then((res) => res.json());
  }
}

export default BestSellers;
