import React from "react";

const GetStartedPage = () => {
  return (
    <main>
      <div className="top-info">
        <h2 className="text-center">Get Started</h2>
      </div>
      <div className="container">
        <div className="row padding-top-sm-78">
          The first thing before users can enjoy best Amazon deals, from UK and
          European Amazon sites, is to add Namia extension to their browser.
          Once the extension is added, its dimmed icon will be visible in the
          left top corner of the browser. <br />
          When a user is looking at a product on Amazon web site and Namia,
          detects cheaper* price the extension icon will be highlighted. In
          cases when the extension cannot find a cheaper price, it’s icon will
          remain deemed. <br />
          Once the icon is highlighted, only a click on Namia’s icon will show a
          list with the countries, product price, delivery costs and total price
          for each Amazon site on which cheaper prices are found. <br />
          Namia takes into account user’s country location and automatically
          converts the VAT, as different countries have different VAT rates.
          <br />
          <p className="paddingTop-15">
            <strong>*</strong> Cheaper in most cases means total price of the product including
            delivery, please note that sometimes Amazon sites do not provide
            accurate delivery costs until you proceed to check out. Namia’s team
            is trying its best to minimise such cases, in which the accurate
            delivery charges are shown only at check out.
          </p>
        </div>
      </div>
    </main>
  );
};

export default GetStartedPage;
