import React from "react";

const AboutPage = () => (
  <main>
    <div className="top-info">
      <h2 className="text-center">About</h2>
    </div>
    <div className="container">
      <div className="row padding-top-sm-78">
        <p>
          Namia is created with the idea to find the best Amazon prices for
          products across UK and Europe.
          </p>
          <p>
          This extension compares prices and delivery costs for goods on Amazon
          sites, which will deliver to user&lsquo;s country. Products might be
          sold on many of the Amazon sites across Europe, but not all sites
          deliver abroad, therefore Namia collates and displays only information
          from shops which provide international delivery to users country location.
          </p>
          <p>
          All prices and costs are based on Amazon daily conversion rates, and
          are shown in the currency for the Amazon site visited (for product
          searches done from Amazon UK, prices are displayed in pounds, for
          searches made from Amazon Germany, prices are displayed in euros).
          </p>
          To keep the extension free for users, Namia might earn a small fee or commission from Amazon, if an order is completed.
        
      </div>
    </div>
  </main>
);

export default AboutPage;
