import React from "react";
import Rating from "react-rating";

const BestSellerForm = (props) => {
  const data = props.data;

  if (data.data.length === 0) {
    return <h3>Nothing</h3>;
  } else {
    return data.data.map((element) => {
      return (
        <>
          <h3 key={element.category} className="marginTop25">
            {element.category}
          </h3>
          <div className="row">
            {element.products.map((prod) => {
              return (
                <>
                  <div className="col-md-4 col-sm-12">
                    <a
                      href={prod.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={prod.img}
                        className="img-fluid padding10 mx-auto d-block maxHeight-300"
                      />
                    </a>
                    <div className="font-large" key={prod.description}>
                      <a
                        hrev={prod.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {prod.description}
                      </a>
                    </div>
                    {prod.reviewsNumber != "" && (
                      <div>
                        <a
                          href={prod.reviewUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Rating
                            initialRating={parseFloat(prod.rating)}
                            emptySymbol={
                              <img
                                src="../../public/img/star.png"
                                className="icon maxHeight-20"
                              />
                            }
                            placeholderSymbol={
                              <img
                                src="../../public/img/star-full.png"
                                className="icon maxHeight-20"
                              />
                            }
                            fullSymbol={
                              <img
                                src="../../public/img/star-full.png"
                                className="icon maxHeight-20"
                              />
                            }
                            readonly
                          />{" "}
                          <span className="vertical-middle">
                            {prod.reviewsNumber}
                          </span>
                        </a>
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </>
      );
    });
  }
};

export default BestSellerForm;
