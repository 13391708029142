import React from "react";
import FollowUs from "./FollowUs";
import { useLocation, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

const Footer = () => {
  let location = useLocation();
  return (
    <footer
      className={
        "footerStyle marginTop25" +
        (location.pathname !== "/" &&
        location.pathname !== "/privacy" &&
        location.pathname !== "/cookie" &&
        location.pathname !== "/index.html"
          ? " footerBottom"
          : "")
      }
    >
      <div className="container-fluid">
        <div className="content">
          <div className="row justify-content-between">
            <div className="col-sm-4 col-12">
              <img
                src="../../public/img/LogoAndText75.png"
                className="img-fluid padding10 mx-auto d-block maxHeight-60"
              />
            </div>
            <FollowUs />
          </div>
        </div>
      </div>
      <div className="footerBackground">
        <div className="container">
          <div className="row paddingTop-25">
            <div className="col-md-3 col-5">
              <ul className="footerTextUl">
                <li>
                  <strong>App</strong>
                </li>
                <li className="padding-top-10">
                  <Link to="/getstarted">Get started</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/download">Download</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-7">
              <ul className="footerTextUl">
                <li>
                  <strong>Legal</strong>
                </li>
                <li className="padding-top-10">
                  <Link to="/privacy">Privacy policy</Link>
                </li>
                <li>
                  <Link to="/cookie">Cookie policy</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-12 padding-bottom-sm-15 text-center">
              <div className="row">
                <div className="col-7 text-center">
                  Criticisms, ideas, recommendations?
                  <Link to="/feedback" className="btn btn-send marginTop-10">
                    <strong>Feedback</strong>
                  </Link>
                </div>
                <div className="col-5 megaphone"></div>
              </div>
            </div>
          </div>
          <div className="row copyRight">
            <div className="col">
              <p className="text-center">© namia {new Date().getFullYear()}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
