import React from "react";

class ContactPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      confirmEmail: "",
      message: "",
      emailError: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    switch (event.target.name) {
      case "name":
        this.setState({ name: event.target.value });
        break;
      case "email":
        this.setState({ email: event.target.value });
        break;
      case "confirmEmail":
        this.setState({ confirmEmail: event.target.value });
        break;
      case "message":
        this.setState({ message: event.target.value });
        break;
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.email !== this.state.confirmEmail) {
      this.setState({ emailError: true });
    }
  }

  sendMessage() {
    fetch("", {
      method: "POST", // POST for create, PUT to update when id already exists.
      headers: { "content-type": "application/json" },
      body: JSON.stringify(this.state)
    })
      .then(); //Show a successful message
  }

  render() {
    return (
      <main>
        <div className="top-info">
          <h2 className="text-center">Get In Touch With Us</h2>
        </div>

        <div className="container">
          <div className="row paddingTop-25">
            <div className="col-12 col-sm-6 offset-sm-3">
              <form className="needs-validation" onSubmit={this.handleSubmit}>
                <div className="col-11">
                  <label>
                    Name <span className="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={this.state.name}
                    name="name"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-11 paddingTop-15">
                  <label
                    className={this.state.emailError ? "invalid-email" : ""}
                  >
                    Email <span className="asterisk">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    required
                    value={this.state.email}
                    name="email"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-11 paddingTop-15">
                  <label
                    className={this.state.emailError ? "invalid-email" : ""}
                  >
                    Confirm Email <span className="asterisk">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    required
                    value={this.state.confirmEmail}
                    name="confirmEmail"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-11 paddingTop-15">
                  <label>
                    Message <span className="asterisk">*</span>
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    required
                    cols="30"
                    rows="5"
                    value={this.state.message}
                    name="message"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="paddingTop-25 paddingLeft-15">
                  <button
                    type="submit"
                    className="btn btn-send padding-side-15"
                    onClick={this.sendMessage}
                  >
                    <strong>Send</strong>
                  </button>
                </div>
              </form>
            </div>
            {/* <div className="col-12 col-sm-6 paddingTop-sm-25 margin-left-sm-15">
              <h3>Contact us by post</h3>
              <div>
                <p className="margin-bottom-0">NaMia</p>
                <p className="margin-bottom-0">11 Llys Eithin</p>
                <p className="margin-bottom-0">Mold</p>
                <p className="margin-bottom-0">Clwyd</p>
                <p>CH7 6GL</p>
              </div>
            </div> */}
          </div>
        </div>
      </main>
    );
  }
}

export default ContactPage;
